<template>
  <el-form-item :label="itemData.label"
                v-bind="[itemData]"
                :prop="itemKey"
                :ref="itemData.refName">
    <slot v-bind="[itemData]"></slot>
  </el-form-item>
</template>

<script>

export default {
  props: {

    // 表单项配置
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 表单键值
    itemKey: String,

    // 父组件双向绑定的model值
    value: {
      type: [Array, Object, Date, String]
    }
  },
  model: {
    // 与父组件进行双向绑定
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      itemVal: this.value
    }
  },
  methods: {
    // input方法触发父组件同步更新数据
    change(e) {
      this.$emit('change', this.itemVal)
    }
  }
}

</script>
